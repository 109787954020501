import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import "./posts.less"

const Posts = ({ title, children, id, posts }) => {
  return (
    <section id={id} className="posts">
      <h1>{title}</h1>
      {children}
      <div className="grid">
        {posts.map(({ node }, index) => (
          <Link to={`/${node.frontmatter.slug}`} className="card" key={index}>
            {node.frontmatter.cover ? (
              <Img
                className="fade"
                fluid={node.frontmatter.cover.childImageSharp.fluid}
              />
            ) : (
              ""
            )}
            <div className="content">
              <h3>{node.frontmatter.title}</h3>
              <p>{node.frontmatter.date}</p>
            </div>
          </Link>
        ))}
      </div>
    </section>
  )
}

Posts.propTypes = {
  title: PropTypes.string,
  posts: PropTypes.array,
  id: PropTypes.string,
}

export default Posts
