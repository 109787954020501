import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Posts from "../components/posts/posts"

export default ({ pageContext, data }) => {
  return (
    <Layout>
      <SEO title="Tags" />
      <Posts title={`#${pageContext.tag}`} posts={data.allMdx.projects}>
        <p>{`${data.allMdx.totalCount} posts found for the ${
          pageContext.tag
        } tag.`}</p>
      </Posts>
    </Layout>
  )
}

export const query = graphql`
  query($tag: String) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      projects: edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            slug
            date(formatString: "MMM DD, YYYY")
            cover {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
